import React, { useState, useEffect } from 'react';
import { Layout } from '../../components/Layout';
import { LoaderCard } from '../../components/Loaders';
import { ModalSuccessAndError } from '../../components/ModalSuccessAndError';
import { axiosInstance, axiosInstanceWithAuth } from '../../services/axios-instance';
import { Footer } from '../Footer';
import { Menu } from '../Menu';
import { Top } from '../Menu/components/MenuAd';
import styles from './AutoscarPlayManagement.module.css';

const GerenciamentoAutoscarPlay = () => {
  const [liveLink, setLiveLink] = useState('');
  const [isLiveOnline, setIsLiveOnline] = useState(false);
  const [videoLinks, setVideoLinks] = useState(['', '', '']);
  const [openModal, setOpenModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const modalConfig = isSuccess
    ? { title: 'Parabéns!', subTitle: 'Suas alterações foram salvas com sucesso.', success: true }
    : { title: 'Ops!', subTitle: 'Ocorreu um erro ao completar sua ação.', success: false };

  useEffect(() => {
    const fetchLiveStatus = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get('/play');
        const { link, online, youtubeLinks } = response.data;
        setLiveLink(link);
        setIsLiveOnline(online);
        setVideoLinks(youtubeLinks || ['', '', '']);
      } catch (error) {
        console.error('Erro ao buscar dados do AutoscarPlay:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLiveStatus();
  }, []);

  const controlModal = () => setOpenModal(prevState => !prevState);

  const handleSaveChanges = async () => {
    try {
      setIsLoading(true);
      await axiosInstanceWithAuth.put('/play', {
        link: liveLink,
        online: isLiveOnline,
        youtubeLinks: videoLinks,
      });
      setIsSuccess(true);
    } catch (error) {
      console.error('Erro ao salvar as alterações:', error);
      setIsSuccess(false);
    } finally {
      setOpenModal(true);
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      <Top />
      <Menu menuHasTwoColors={false} />
      <div className={styles.gerenciamentoContainer}>
        <h1 className={styles.Title}>Gerenciamento AutoscarPlay</h1>
        {isLoading ? (
          <LoaderCard />
        ) : (
          <>
            <div className={styles.section}>
              <label htmlFor="liveLink">Link da Live do YouTube:</label>
              <input
                type="text"
                id="liveLink"
                value={liveLink}
                onChange={e => setLiveLink(e.target.value)}
                className={styles.input}
              />
            </div>

            <div className={styles.section}>
              <label htmlFor="liveStatus">Live está Online?</label>
              <input
                type="checkbox"
                id="liveStatus"
                checked={isLiveOnline}
                onChange={e => setIsLiveOnline(e.target.checked)}
                className={styles.checkbox}
              />
            </div>

            <div className={styles.section}>
              <h3>Links dos Vídeos da Página Principal:</h3>
              {videoLinks.map((link, index) => (
                <div key={index}>
                  <label htmlFor={`videoLink${index}`}>Link do Vídeo {index + 1}:</label>
                  <input
                    type="text"
                    id={`videoLink${index}`}
                    value={link}
                    onChange={e => {
                      const newVideoLinks = [...videoLinks];
                      newVideoLinks[index] = e.target.value;
                      setVideoLinks(newVideoLinks);
                    }}
                    className={styles.input}
                  />
                </div>
              ))}
            </div>

            <button onClick={handleSaveChanges} className={styles.saveButton}>
              Salvar Alterações
            </button>
          </>
        )}
      </div>
      <Footer />

      <ModalSuccessAndError
        {...modalConfig}
        isOpen={openModal}
        actionBtn={() => {
          controlModal();
          if (isSuccess) window.location.reload();
        }}
        closeModal={controlModal}
        actionBtnSecondary={controlModal}
        textBtnClose="OK"
      />
    </Layout>
  );
};

export { GerenciamentoAutoscarPlay };
