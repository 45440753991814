import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { inject, observer } from 'mobx-react';
import { GerenciamentoAutoscarPlay } from '../modules/AutoscarPlayManagement';

interface UserStore {
  profile: {
    admin: boolean;
  };
}

interface Props {
  user: UserStore;
}

const GerenciamentoAutoscarPlayPage: React.FC<Props> = ({ user }) => {
  const { profile } = user;
  const { admin } = profile;

  useEffect(() => {
    if (!admin) {
      navigate('/');
    }
  }, [admin]);

  if (!admin) {
    return null;
  }

  return <GerenciamentoAutoscarPlay />;
};

export default inject('user')(observer(GerenciamentoAutoscarPlayPage));
